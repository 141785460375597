<template>
  <Modal :id="id" size="lg" title="Termos de Uso" subtitle="Leia atentamente os termos de uso antes de prosseguir."
    @hidden="close" @close="close" :preventClose="true">
    <div class="content">
      <section>
        <iframe height="27rem" v-if="existVideo && techOrder === 'panda'" width="100%" :src="midiaTermosUrl"
          style="border: 0; pointer-events: all; height: 45vh"></iframe>
        <video ref="videoPlayer" v-else-if="existVideo && techOrder !== 'iframe'" class="video-js widthVideoPlayer"
          :tech-order="techOrder" :autoplay="autoplay" :controls="true" v-on:pause="onVideoPause"
          @changed="playerStateChanged($event)">
          <source :src="src" :type="type" />
        </video>
        <div v-html="src" v-else></div>
      </section>
      <section>
        <EditorContentView v-html="termosPainel" />
      </section>
    </div>
    <Divider margin="1" />
    <section class="flex-row justify-between items-center">
      <b-form-checkbox v-model="optout" name="optout">
        Aceito e concordo com os termos
      </b-form-checkbox>
      <ButtonPlay :disabled="!optout" type="normal" size="lg" @click="submit">
        Aceitar
      </ButtonPlay>
    </section>
    <ModalTermsOfUseAdvice :visible="advice" @hidden="advice = false" @submit="submit" />
  </Modal>
</template>

<script>
import MetaService from "@/services/resources/MetaService";
const serviceMeta = MetaService.build();
import "video.js/dist/video-js.css";
import videojs from "video.js";
import "@devmobiliza/videojs-vimeo/dist/videojs-vimeo.esm";
import "videojs-youtube";
import EditorContentView from "@/components/EditorContentView.vue";
import { mapActions, mapGetters } from "vuex"

export default {
  components: {
    EditorContentView,
    ModalTermsOfUseAdvice: () => import("@/components/ModalTermsOfUseAdvice.vue")
  },
  props: {
    "id": {
      default: "modal-termos"
    }
  },
  data() {
    return {
      termosPainel: "",
      midiaTermos: null,
      midiaTermosUrl: null,
      mediaTypeLesson: "",
      step: 0,
      autoplay: true,
      player: null,
      techOrder: "youtube",
      type: "video/youtube",
      src: "",
      statusCheck: false,
      toEnd: false,
      existVideo: true,
      optout: false,
      advice: false,
    };
  },
  computed: {
    ...mapGetters({
      getMe: "auth/getMe"
    }),
    siteId() {
      return this.getMe.current?.currentSite?.id
    }
  },
  methods: {
    ...mapActions({
      actionShowModalTermsOfUse: "home/actionShowModalTermsOfUse",
      actionSaveSiteMetas: "user/actionSaveSiteMetas",
      actionGetMetaSiteScoped: "user/actionGetMetaSiteScoped",
      actionSaveMeta: "user/actionSaveMeta"
    }),
    scrollFunction() {
      let element = document.getElementById("spaceText");
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        this.toEnd = true;
      } else {
        this.toEnd = false;
      }
    },
    reachedEndOfText(reached) {
      if (reached) {
        this.toEnd = true;
        //console.log('to end xxxxxxxxxxxx')
      }
    },
    async submit() {
      await this.actionSaveMeta({
        key: `_termos_${this.siteId}`,
        value: "accepted"
      });
      this.$bvModal.hide(this.id);
      this.$emit('closeModalTermsOfUse');
    },
    prev() {
      this.getMetaTermosVideo();
      this.step = this.step - 1;
    },
    next() {
      this.step = this.step + 1;
    },
    getMetaTermosVideo() {
      this.$root.$emit("loadOn");
      serviceMeta
        .search("keys[]=introductoryVideo")
        .then((resp) => {
          //console.log('xxxxxxxxx', resp, JSON.parse(resp.introductoryVideo));
          var data = JSON.parse(resp.introductoryVideo);
          if (resp.introductoryVideo === null || data.url === null) {
            this.step = 1;
            this.existVideo = false;
          } else {
            // console.log("meta video termos", data);
            this.midiaTermos = data.thumb;
            this.midiaTermosUrl = data.url;
            this.src = data.url;
            this.techOrder = data.type;
            if (data.type === "vimeo") {
              this.type = "video/vimeo";
            } else if (data.type === "youtube") {
              this.type = "video/youtube";
            } else {
              return;
            }
            // console.log(this.src, this.type);
            setTimeout(() => {
              this.getPlayer();
            }, 1000);
          }
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    getMetaTermosTxt() {
      serviceMeta
        .search("keys[]=termsOfUse")
        .then((resp) => {
          //console.log("meta txt termos", resp);
          this.termosPainel = resp.termsOfUse;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    showModalUpload() {
      this.$root.$emit("videotermos");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    playerDispose() {
      this.player.dispose();
    },
    playerGetTime() {
      return this.player.currentTime();
    },
    getPlayer() {
      this.player = videojs(
        this.$refs.videoPlayer,
        this.options,
        function onPlayerReady() {
          //console.log('onPlayerReady', this);
        }
      );
      //console.log("xxx", this.player);
    },
    onVideoPause: function () {
      //console.log('video is paused')
    },
    play() {
      this.player.play();
    },
    pause() {
      this.player.pause();
    },
    playerStateChanged: function (playerCurrentState) {
      //console.log('player current update state', playerCurrentState);
    },
    close() {
      this.advice = true;
    },
    show() {
      this.$bvModal.show(this.id);
    },
    async load() {
      const shouldShow = await this.actionShowModalTermsOfUse();
      if (!shouldShow) {
        return
      }
      this.getMetaTermosTxt();
      this.getMetaTermosVideo();
      this.show();
    }
  },
  mounted() {
    this.load();
  },
};
</script>

<style lang="scss" scoped>
.spaceModal {
  padding: 40px;
  margin-top: -40px;

  p {
    color: #000000;
  }
}

.hide {
  display: none !important;
}

.disableBtn {
  background: #f7f7f7 !important;
  border: 1px solid #f7f7f7 !important;
  color: #81858e !important;
  pointer-events: none;
}

.spaceText {
  height: 30vh;
  overflow: auto;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.next {
  background: var(--maincolor);
  border: 1px solid var(--maincolor);
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
  border-radius: 30px;
  height: 45px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.2px;
  color: #ffffff;
  width: 251.29px;
}

.prev {
  background: #f7f7f7;
  border: 1px solid #f7f7f7;
  box-sizing: border-box;
  border-radius: 30px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.2px;
  color: #81858e;
  width: 180px;
  height: 45px;
  margin-right: 10px;
}

.btn-openModal {
  background: var(--maincolortrans);
  border: 2px dashed var(--maincolor);
  box-sizing: border-box;
  border-radius: 30px;
  height: 55px;
  cursor: pointer;

  .text {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--maincolor) !important;
    display: flex;
    justify-content: center;
    margin-top: 17px;
  }
}

.btn-openModalPreview {
  background: var(--maincolortrans);
  border: 2px dashed var(--maincolor);
  box-sizing: border-box;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;

  img {
    border-radius: 3px;
  }

  .imagePreviewThumb {
    height: 150px;
  }
}

.lineDiv {
  width: 100%;
  height: 0.5px;
  background: #e5e5e5;
  transform: matrix(1, 0, 0, -1, 0, 0);
  margin-top: 30px;
  margin-bottom: 30px;
}

.header {
  .title {
    font-family: Inter;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    color: #333333;
    margin-bottom: 5px;
  }

  .subtitle {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #81858e;
  }

  .close {
    cursor: pointer;
  }
}

.justify-between {
  justify-content: space-between;
}

.content {
  // max-height: 60vh;
  flex: 1;
  overflow: auto;
}
</style>
