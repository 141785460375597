<template>
  <div class="ql-editor ql-editor-view" />
</template>

<script>
  import "quill/dist/quill.core.css";

  export default {
    name: 'EditorContentView',
  }
</script>

<style lang="scss">

[data-theme="dark"] {
  .ql-editor.ql-editor-view { 
    color: var(--fontcolor) !important;
  }

  blockquote {
    background: #1c1c1c !important;

    &:before {
      color: var(--fontcolor) !important;
    }
  }

}

.ql-editor.ql-editor-view {
  box-sizing: initial;
  cursor: initial;
  line-height: initial;
  height: initial;
  outline: initial;
  overflow-y: initial;
  tab-size: initial;
  -moz-tab-size: initial;
  text-align: initial;
  white-space: initial;
  word-wrap: initial;
  word-break: break-word;
  line-height: 1.5;
  font-size: 14px;
  color: #000000;

  blockquote {
    background: #f9f9f9;
    border-left: 10px solid #ccc;
    margin: 1.5em 10px;
    padding: 10px;
    quotes: "\201C""\201D""\2018""\2019";

    &:before {
      color: #ccc;
      content: open-quote;
      font-size: 4em;
      line-height: 0.1em;
      margin-right: 0.25em;
      vertical-align: -0.4em;
    }

    p {
      display: inline;
    }
  }
}
</style>